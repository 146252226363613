import MMS from "../assets/Images/Works/mms.png";
import USJ from "../assets/Images/usj.png";
import CDEPD from "../assets/Images/cdepd.png";
import Virtual from "../assets/Images/Works/Virtual.png";

import React from "../assets/Images/Stack/react.png";
import Angular from "../assets/Images/Stack/angular.png";
import Express from "../assets/Images/Stack/express.png";
import Node from "../assets/Images/Stack/nodejs.png";
import Docker from "../assets/Images/Stack/docker.png";
import Mongo from "../assets/Images/Stack/mdb.png";
import Firebase from "../assets/Images/Stack/firebase.png";
import Jest from "../assets/Images/Stack/Jest.png";
import JS from "../assets/Images/Stack/JS.png";
import NextJS from "../assets/Images/Stack/NextJS.png";
import Nginx from "../assets/Images/Stack/nginx.png";
import Python from "../assets/Images/Stack/python.png";
import Redux from "../assets/Images/Stack/Redux.png";
import RN from "../assets/Images/Stack/RN.png";
import Sass from "../assets/Images/Stack/sass.png";
import TS from "../assets/Images/Stack/TS.png";
import Wordpress from "../assets/Images/Stack/wordpress.png";

export const Images = {
  work: {
    MMS,
    USJ,
    Virtual,
  },
  maintain: {
    USJ,
  },
  stack: {
    Angular,
    Docker,
    Express,
    Firebase,
    Jest,
    JS,
    Mongo,
    NextJS,
    Nginx,
    Node,
    Python,
    React,
    Redux,
    RN,
    Sass,
    TS,
    Wordpress,
  },
  CDEPD,
};
