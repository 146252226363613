import React, { Component } from "react";
import "./index.css";
import pageConsts from "../../consts/pageConsts.json";

export default class Card extends Component {
  componentDidMount() {
    this.props.descriptionHeight(
      document.getElementById(`${this.props.id}-desc`).offsetHeight
    );
  }
  getDescription = (content) => {
    const items = content.split(/\${(.*?)}/);
    let count = 0;
    if (items.length > 1) {
      items.forEach((element, index) => {
        if (index % 2 !== 0) {
          items[index] = (
            <a
              key={index}
              target="_blank"
              href={this.props.hyperlinks[count].link}
              className="card-desc-hyperlink"
              rel="noreferrer"
            >
              {element}
            </a>
          );
          count = count + 1;
        }
      });
      return items;
    }
    return content;
  };
  render() {
    const { isAvailable, newDescHeight } = this.props;
    return (
      <div className="card-content card-shadow ">
        <div className="card-text-content">
          <img
            className="card-image"
            alt={`${this.props.title} Figure`}
            src={this.props.image}
          />
          <h4 className="card-title">{this.props.title}</h4>
          <span id={`${this.props.id}-desc`} className="card-desc">
            {this.getDescription(this.props.description)}
          </span>
        </div>
        <button
          type="button"
          className={`card-button ${isAvailable ? "" : "disabled"}`}
          disabled={!isAvailable}
          onClick={() => window.open(this.props.href)}
        >
          {isAvailable
            ? pageConsts.buttons.letMeSee
            : pageConsts.buttons.coming}
        </button>
      </div>
    );
  }
}
