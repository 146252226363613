import React, { Component } from "react";
import { HashLink } from "react-router-hash-link";
import StickyBox from "react-sticky-box";

import pageConstants from "../../consts/pageConsts.json";
import { Images } from "../../theme/Images";
import "./index.css";

export default class TopNavBar extends Component {
  constructor() {
    super();
    this.NavItems = Object.values(NavItems);
    this.state = {
      topBarShadowActivated: false,
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  }

  onScroll = (evt) => {
    if (window.scrollY > 0 && !this.state.topBarShadowActivated) {
      this.setState({ topBarShadowActivated: true });
    } else if (window.scrollY === 0 && this.state.topBarShadowActivated) {
      this.setState({ topBarShadowActivated: false });
    }
  };

  onClickNavItem = (item) => {
    switch (item.value) {
      case NavItems.Home.value:
        break;
      case NavItems.About.value:
        break;
      case NavItems.Work.value:
        break;
      case NavItems.Tech.value:
        break;
      default:
        break;
    }
  };

  render() {
    const mainCls = "top-nav-bar shadow-activated";

    return (
      <StickyBox offsetTop={0} className="top-nav-bar-container">
        <div className={mainCls}>
          <img
            onClick={() => window.open("http://cdepd.fhss.sjp.ac.lk")}
            className="cdepd-logo"
            src={Images.CDEPD}
            alt="CDEPD Logo"
          />
          <div className="top-nav-items">
            {Array(this.NavItems.length)
              .fill()
              .map((_, i) => i)
              .map((i) =>
                this.NavItems[i].isHashLink ? (
                  <HashLink
                    smooth
                    key={this.NavItems[i].id}
                    to={this.NavItems[i].href}
                    className="top-nav-text"
                  >
                    {pageConstants.top_nav[this.NavItems[i].id]}
                  </HashLink>
                ) : (
                  <a className="top-nav-text" href={this.NavItems[i].href}>
                    {pageConstants.top_nav[this.NavItems[i].id]}
                  </a>
                )
              )}
          </div>
        </div>
      </StickyBox>
    );
  }
}

const NavItems = {
  Home: {
    id: "home",
    value: "Home",
    isHashLink: true,
    href: "",
  },
  About: {
    id: "about",
    value: "About",
    isHashLink: false,
    href: "http://cdepd.fhss.sjp.ac.lk/about-us/",
  },
  Work: {
    id: "work",
    value: "Work",
    isHashLink: true,
    href: "#work",
  },
  Tech: {
    id: "tech",
    value: "Tech Stack",
    isHashLink: true,
    href: "#tech",
  },
};
