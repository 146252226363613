import React, { Component } from "react";
import Card from "../../components/card";
import pageConsts from "../../consts/pageConsts.json";
import "./index.css";
import { Images } from "../../theme/Images";

export default class WorkView extends Component {
  constructor() {
    super();
    this.descHeight = 0;
  }

  manageDescriptionHeight = (height) => {
    if (height > this.descHeight) {
      this.descHeight = height;
      this.forceUpdate();
    }
  };

  render() {
    const { items } = pageConsts[this.props.type];
    return (
      <div className="content" id="work">
        <h2 className="work-title">{pageConsts[this.props.type].title}</h2>
        <div className="work-card-container">
          {Array(items.length)
            .fill()
            .map((_, i) => i)
            .map((i) => (
              <Card
                key={`work-${i}`}
                id={i}
                description={items[i].description}
                image={Images[this.props.type][items[i].imgName]}
                title={items[i].title}
                isAvailable={items[i].isAvailable}
                href={items[i].href}
                hyperlinks={items[i].hyperlinks}
                descriptionHeight={this.manageDescriptionHeight}
                newDescHeight={this.descHeight}
              />
            ))}
        </div>
      </div>
    );
  }
}
